import React, { useContext, FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Helmet from 'react-helmet';
import Box from '@material-ui/core/Box';
import { OutageCenterPage } from '../../components/outage';
import { Redirect, Router } from '@reach/router';
import ROUTES from '../../routes';
import { useTranslation } from '../../hooks/useTranslation';
import { featureFlagsContext } from '../../providers/FeatureFlagsProvider';
import Backdrop from '../../components/backdrop';
import { OutageMapNotificationContextProvider } from '../../providers/OutageMapNotificationProvider';
import OutageMapNotifications from '../../components/outage-map-notifications';
import useContactDataInterceptModal from '../../hooks/useContactDataInterceptModal';
import ContactDataInterceptModal from '../../components/contact-data-intercept-modal';
import BroadcastOutageCommunication from '../../components/outage/outage-center/broadcast-outage-communication/BroadcastOutageCommunication';

const Outages: FC = () => {
  const { t } = useTranslation();
  const { loading, contactDataIntercept } = useContext(featureFlagsContext);
  const {
    open,
    contactData,
    setOpen,
    setIsSessionDismissed,
  } = useContactDataInterceptModal(contactDataIntercept);

  const data = useStaticQuery(graphql`
    query contentfulApplicationPage {
      contentfulApplicationPage(
        contentful_id: { eq: "7DofE2F79s5h7nHzzNG86W" }
      ) {
        pageTitle
        metaDescription
      }
    }
  `);

  const { metaDescription, pageTitle } = data && data.contentfulApplicationPage;

  return (
    <>
      <Helmet bodyAttributes={{ class: 'TemplatePage' }}>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      {loading ? (
        <Backdrop forceOpen />
      ) : (
        <>
          <OutageMapNotificationContextProvider>
            <Router basepath="/">
              <OutageCenterPage path={ROUTES.OUTAGES} />

              {/*Redirecting all the sub pages to /outages,since it is showing empty page for sub path*/}
              <Redirect from="/*" to={ROUTES.OUTAGES} default noThrow />
            </Router>

            <Box id="outage-global-messages-container">
              <BroadcastOutageCommunication />
            </Box>
            <Box id="outage-notification-messages-container">
              <OutageMapNotifications />
            </Box>
          </OutageMapNotificationContextProvider>

          {open && (
            <ContactDataInterceptModal
              open={open}
              onClose={() => setOpen(false)}
              contactData={contactData}
              setIsSessionDismissed={setIsSessionDismissed}
            />
          )}
        </>
      )}
    </>
  );
};

export default Outages;
