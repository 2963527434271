import add from 'date-fns/add';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import moment from 'moment';
import { ExpiryDate, Maybe, Scalars } from '../__generated__/pge-types';
import { SupportedLanguages } from '../providers/LanguageProvider';
import { addOrdinalSuffixToNumber, dateSuffixMap } from './format';

export const DATE_FORMAT = 'MM/DD/YYYY';

export const getOneMonthInFuture = (
  value?: Date | string | number | undefined,
): Date => {
  const today: Date = value ? new Date(value) : new Date();
  const futureDate = add(today, { months: 1 });
  return futureDate;
};

export const isExpired = ({ month, year }: ExpiryDate) => {
  const now = new Date(Date.now());
  const expiry = parse(`${month}/${year}`, 'MM/yyyy', now);

  return (
    now.getFullYear() > expiry.getFullYear() ||
    (now.getFullYear() === expiry.getFullYear() &&
      now.getMonth() > expiry.getMonth())
  );
};

export const parseLocalDate = (dateStr: Scalars['LocalDate'], refDate?: Date) =>
  parse(dateStr, 'yyyy-MM-dd', refDate || new Date());

export const formatLocalDate = (date: Date = new Date()) =>
  format(date, 'yyyy-MM-dd');

export const parseTimeRange = (
  timeRange: string,
  date: string,
): { start: string; end: string } | null => {
  const [startStr, endStr] = timeRange.split('-').map(str => str.trim());

  if (!startStr || !endStr) {
    return null;
  }

  const startDate = parse(
    `${date} ${startStr.toUpperCase()}`,
    'MM/dd/yyyy h a',
    new Date(),
  );
  const endDate = parse(
    `${date} ${endStr.toUpperCase()}`,
    'MM/dd/yyyy h a',
    new Date(),
  );

  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    return null;
  }

  const start = format(startDate, 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX');
  const end = format(endDate, 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX');

  return { start, end };
};

export const getTimeRangeString = (serviceTime: {
  start: string;
  end: string;
}): { date: string; timeRange: string; hourTimeRange: string } | null => {
  const { start, end } = serviceTime;

  const startDate = parse(start, 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX', new Date());
  const endDate = parse(end, 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX', new Date());

  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    return null;
  }

  const formattedStartTime = format(startDate, 'h:mm a').toLowerCase();
  const formattedEndTime = format(endDate, 'h:mm a').toLowerCase();

  const formattedHourStartTime = format(startDate, 'h a').toLowerCase();
  const formattedHourEndTime = format(endDate, 'h a').toLowerCase();

  return {
    date: format(startDate, 'MM/dd/yyyy'),
    timeRange: `${formattedStartTime} - ${formattedEndTime}`,
    hourTimeRange: `${formattedHourStartTime} - ${formattedHourEndTime}`,
  };
};

export const formatDateInSpanish = (date: Date) => {
  return new Intl.DateTimeFormat('es-ES', {
    day: 'numeric',
    month: 'long',
  }).format(new Date(date));
};

export const formatDateToLongFormat = (
  date: Date,
  language: SupportedLanguages,
  t: (translationField: string) => string,
) => {
  if (isNaN(date.getTime())) {
    throw new Error('Invalid date string');
  }

  if (language === SupportedLanguages.Spanish) {
    return formatDateInSpanish(date);
  } else {
    const timezoneOffset = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() + timezoneOffset);
    // Get month name
    const monthOptions: Intl.DateTimeFormatOptions = { month: 'long' };
    const monthName = date.toLocaleDateString('en-US', monthOptions);
    // Format month based on length
    const formattedMonth =
      monthName.length > 4 ? monthName.substring(0, 3) + '.' : monthName;

    // // Format day
    const day = date.getDate();
    const daySuffix = dateSuffixMap(t, day);
    return `${formattedMonth} ${day}${daySuffix}`;
  }
};

export const formatDateToMonth = (date: Date, language: SupportedLanguages) => {
  if (isNaN(date.getTime())) {
    throw new Error('Invalid date string');
  }
  const timezoneOffset = date.getTimezoneOffset();
  date.setMinutes(date.getMinutes() + timezoneOffset);
  if (language === SupportedLanguages.Spanish) {
    return formatDateInSpanish(date);
  } else {
    // Get month name
    const monthOptions: Intl.DateTimeFormatOptions = { month: 'long' };
    const monthName = date.toLocaleDateString('en-US', monthOptions);

    return monthName;
  }
};

export const formatFullDateWithYear = (
  date: Date,
  language: SupportedLanguages,
  addOrdinalSuffix: boolean = false,
) => {
  const timezoneOffset = date.getTimezoneOffset();
  date.setMinutes(date.getMinutes() + timezoneOffset);
  const day = date.getDate();
  const year = date.getFullYear();
  const month = formatDateToMonth(date, language);

  return `${month} ${
    addOrdinalSuffix ? addOrdinalSuffixToNumber(day) : day
  }, ${year}`;
};

export const formatFullMonthWithDay = (
  date: Date,
  language: SupportedLanguages,
  t: (translationField: string) => string,
) => {
  const timezoneOffset = date.getTimezoneOffset();
  date.setMinutes(date.getMinutes() + timezoneOffset);
  const day = date.getDate();
  const fullMonth = formatDateToMonth(date, language);
  const daySuffix = dateSuffixMap(t, day);

  return `${fullMonth} ${day}${daySuffix}`;
};

/**
 * Utility to format date string to the specified format
 * @param dateString input date string
 * @param dateFormat input format string or if not provided then default format is MM/DD/YYYY
 * @returns formatted date string
 */
export function formatDateString(
  dateString?: Maybe<string>,
  dateFormat: string = DATE_FORMAT,
): string {
  return dateString ? moment(dateString).format(dateFormat) : '';
}
